import React, { useState } from 'react'
import axios from 'axios'
import advent_days from '../advent_days_SI'
import Modal from './Modal'
import FileInput from 'react-simple-file-input'

import starHead from '../assets/img/grafika/modal-zvijezda.png'

const CalendarForm = (props) => {

  const { selectedItem } = props;
  const allowedFileTypes = ["image/png", "image/jpeg", "application/pdf"];

  const [formFields, setFormFields] = useState({
    ime_i_prezime: '',
    grad_i_postanski_broj: '',
    adresa: '',
    email: '',
    telefon: '',
    /*files: '',
    filesContents: [],
    filesPreview: [],
    file: false,
    filePreview: '',
    totalFileSize: 0,*/
    odgovor: ''
  });
  const [uploadedFiles, setUploadedFiles] = useState(false);
  const [answersAdded, setAnswersAdded] = useState(false); 
  const [newsletter, setNewsletter] = useState(false); 
  const [suglasnostPodaci, setSuglasnostPodaci] = useState(false); 
  const [suglasnostPravila, setSuglasnostPravila] = useState(false); 
  
  const [formMessages, setFormMessages] = useState({
    successMessage: false,
    errorMessage: '',
    messageSent: false,
    errorImePrezime: '',
    errorGradPosta: '',
    errorAdresa: '',
    errorEmail: '',
    errorTelefon: '',
    /*errorSlikaRacuna: '',
    errorFileType: '',*/
    errorAnswers: '',
    errorSuglasnostPodaci: '',
    errorSuglasnostPravila: '',
  });
  
  const handleChangeNewsletter = () => { 
    setNewsletter(!newsletter); 
  }; 
  const handleChangeSuglasnostPodaci = () => { 
    setSuglasnostPodaci(!suglasnostPodaci); 
  }; 
  const handleChangeSuglasnostPravila = () => { 
    setSuglasnostPravila(!suglasnostPravila); 
  }; 
  const handleChangeAnswers = () => { 
    setAnswersAdded(!answersAdded); 
  }; 

  const handleAnswers = (value) => {

    handleChangeAnswers();
    setFormFields((prevFields) => ({
      ...prevFields,
      odgovor: value,
    }));
 
    setFormMessages({
      ...formMessages,
      errorAnswers: ""
    });
    
  };

  const questionsPerDay = {
    day1: [
        { id: "question1", order: 1, text: "Katero izmed navedenih arom bi izbrali?", options: ["a) Lešnik", "b) Čokolada", "c) Jagoda", "d) Punč"] },
    ],
    day2: [
        { id: "question2", order: 2, text: "Dr. Oetker Creative pudingi so posebni zaradi fuzije dveh okusov. Katero kombinacijo bi izbrali?", options: ["a) Kokos-bela čokolada", "b) Mango-kurkuma", "c) Jagode-smetana", "d) Ananas-kokos"] },
    ],
    day3: [
        { id: "question3", order: 3, text: "Katero izmed navedenih sladic želite videti v sodobnejši izvedbi?", options: ["a) Kremne rezine", "b) Rožato", "c) Torto Reform", "d) Orehovo potico"] },
    ],
    day4: [
        { id: "question4", order: 4, text: "Kakšen je vaš popoln zajtrk?", options: ["a) Muesli", "b) Ovsena kaša", "c) Palačinke ali vaflji", "d) Sendvič ali karkoli s kruhom"] },
    ],
    day5: [
        { id: "question5", order: 5, text: "Izmed osvežujočih desertov izberem:", options: ["a) Desert v lončku", "b) Skutina torta (Cheesecake)", "c) Sladoled", "d) Sorbet"] },
    ],
    day6: [
        { id: "question6", order: 6, text: "Katerega od naslednjih okusov Super kaše želite pokusiti?", options: ["a) Lešnik", "b) Bela čokolada-borovnica", "c) Slana karamela", "d) Pomaranča-čokolada"] },
    ],
    day7: [
        { id: "question7", order: 7, text: "Ko gre za beljakovine, jih imate najraje v:", options: ["a) Pudingu", "b) Šejku", "c) Čokoladnem napitku", "d) Tortica v skodelici (Mug cake)"] },
    ],
    day8: [
        { id: "question8", order: 8, text: "Obožujete rjavčke in poleg klasičnih okusov imate radi tudi:", options: ["a) Brookiese", "b) Cheesecake rjavčke", "c) Rocky Road rjavčke", "d) Marmorne rjavčke"] },
    ],
    day9: [
        { id: "question9", order: 9, text: "Na moji praznični mizi so vedno:", options: ["a) Piškotki s koščki čokolade", "b) Vanilijevi rogljički", "c) Linški piškoti", "d) Razpokančki"] },
    ],
    day10: [
        { id: "question10", order: 10, text: "Ste ljubitelji mešanic za muffine, vendar želite pokusiti nekaj novega? Katero slaščico bi izbrali?", options: ["a) Krofe", "b) Buhtlje", "c) Slane muffine", "d) Rogljičke"] },
    ],
    day11: [
        { id: "question11", order: 11, text: "S čim bi najraje okrasili sladice?", options: ["a) Sladkorne oči", "b) Vafljeve palčke", "c) Čokoladna srca", "d) Čokoladne črke"] },
    ],
    day12: [
        { id: "question12", order: 12, text: "Med novimi dekoracijami želite videti:", options: ["a) Hostije za torte", "b) Več čokoladnih dekoracij", "c) Več sladkornih drobtinic in drobnih dekoracij", "d) Več perlic za okrasitev"] },
    ],
    day13: [
        { id: "question13", order: 13, text: "Ko dobite goste, bi za hitro rešitev izbrali:", options: ["a) Rolado", "b) Sacher torto", "c) Sadni kolač", "d) Šamrole"] },
    ],
    day14: [
        { id: "question14", order: 14, text: "Kateri novi okus mueslijev bi vas navdušil?", options: ["a) Čokolada-banana", "b) Jogurt-jagoda", "c) Hrustljavi z rozinami", "d) Lešnik in žita"] },
    ],
    day15: [
        { id: "question15", order: 15, text: "Katere piškote želite videti v božični ponudbi?", options: ["a) Vanilijeve rogljičke", "b) Čokoladne piškotke različnih oblik", "c) Mešane tradicionalne piškote", "d) Kokosove piškote"] },
    ],
    day16: [
        { id: "question16", order: 16, text: "Katero kremo bi imeli najraje v svojem desertu v lončku?", options: ["a) Malina-smetana", "b) Stračatela", "c) Karamela-smetana", "d) Klasično – čokolada ali vanilija"] },
    ],
    day17: [
        { id: "question17", order: 17, text: "Kaj bi najraje pripravili za praznično mizo?", options: ["a) Desert v lončku z okusom cimeta s prazničnimi dekoracijami", "b) Čokoladni narastek", "c) Pito", "d) Torto"] },
    ],
    day18: [
        { id: "question18", order: 18, text: "Katero knjigo z recepti bi najraje prejeli za darilo?", options: ["a) Praznični piškoti", "b) Rojstnodnevne torte za odrasle in otroke", "c) Recepti za veliko noč", "d) Hitri kolači"] },
    ],
    day19: [
        { id: "question19", order: 19, text: "Kateri okus pice želite pokusiti?", options: ["a) Okus rukole in mocarele", "b) Tuna", "c) Hawaii", "d) Z zelenjavo in čilijem"] },
    ],
    day20: [
        { id: "question20", order: 20, text: "Kaj od naslednjega bi najraje prejeli za božič?", options: ["a) Zvezek z recepti", "b) Model za peko", "c) Mešalnik", "d) Adventni koledar"] },
    ],
    day21: [
        { id: "question21", order: 21, text: "Kateri okus osvežujočega napitka vam je najbolj všeč?", options: ["a) Malina", "b) Limona", "c) Pomaranča", "d) Ananas"] },
    ],
    day22: [
        { id: "question22", order: 22, text: "Kateri okus se vam zdi najbolj zanimiv?", options: ["a) Banana-čokolada", "b) Bela čokolada s kokosom", "c) Stračatela", "d) Pistacija"] },
    ],
    day23: [
        { id: "question23", order: 23, text: "Zanima me naslednji način prehrane:", options: ["a) Low-carb", "b) Veganski", "c) Brezglutenski", "d) Diabetični"] },
    ],
    day24: [
        { id: "question24", order: 24, text: "Naslednje leto načrtujem članstvo v:", options: ["a) Pekovskem klubu", "b) Klubu zajtrkov", "c) Kuharskem klubu", "d) Fit klubu"] },
    ],
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value
    setFormFields({...formFields, [name]: value})

    if(name === 'ime_i_prezime'){
        setFormMessages({
            ...formMessages,
            errorImePrezime: ''
        })
    }
    if(name === 'grad_i_postanski_broj'){
      setFormMessages({
          ...formMessages,
          errorGradPosta: ''
      })
    }
    if(name === 'adresa'){
      setFormMessages({
          ...formMessages,
          errorAdresa: ''
      })
    }
    if(name === 'email'){
      setFormMessages({
          ...formMessages,
          errorEmail: ''
      })
    }
    if(name === 'telefon'){
      setFormMessages({
          ...formMessages,
          errorTelefon: ''
      })
    }
  }

  const fileIsIncorrectFiletype = (file) => {
      if (allowedFileTypes.indexOf(file.type) === -1) {
          return true;
      } else {
          return false;
      }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormMessages({
      ...formMessages,
      successMessage: false,
      //errorFotografija: '',
      errorMessage: '',
      /*errorImage: '',
      errorFileType: '',*/
      errorSuglasnostPodaci: '',
      errorSuglasnostPravila: '',
      errorAnswers: ''
    });

    const formData = new FormData();

    formData.append('ime_i_prezime', formFields.ime_i_prezime)
    formData.append('grad_i_postanski_broj', formFields.grad_i_postanski_broj)
    formData.append('adresa', formFields.adresa)
    formData.append('telefon', formFields.telefon)
    formData.append('email', formFields.email)
    formData.append('odgovor', formFields.odgovor)
    
    if(uploadedFiles){
      formData.append('slika_racun', uploadedFiles);
    } 
    
    formData.append('tekst', formFields.tekst)
    
    formData.append('prijava_newsletter', newsletter ? 1 : 0)

    // disabled submit button
    document.querySelector(".modalSubmit").classList.add("loading");
    
    if(!suglasnostPodaci) {
      setFormMessages({
          ...formMessages,
          errorSuglasnostPodaci: "Vaša privolitev za obdelavo podatkov je obvezna."
      });
      document.querySelector(".modalSubmit").classList.remove("loading");
      return false;
    }
    if(!suglasnostPravila) {
      setFormMessages({
          ...formMessages,
          errorSuglasnostPravila: "Vaše soglasje s pravili nagradne igre je obvezno."
      });
      document.querySelector(".modalSubmit").classList.remove("loading");
      return false;
    }
    if(formFields.odgovor === '') {
      setFormMessages({
          ...formMessages,
          errorAnswers: "Odgovor na vprašanje je obvezen."
      });
      document.querySelector(".modalSubmit").classList.remove("loading");

      return false;
    }

    axios({
        url: '/collect_si.php',
        method: 'post',
        data: formData,
        config: { headers:
            {
                'Content-Type': 'multipart/form-data'
            }
        }
    })
    .then(function(response) {
        if (response.data.success) {
            setFormFields({
                ime_i_prezime: '',
                grad_i_postanski_broj: '',
                adresa: '',
                telefon: '',
                email: '',
               // slika_racun: '',
                errorMessage: "",
                odgovor: ""
                /*tekst: '',
                files: '',
                file: false,
                filesContents: [],
                filePreview: [],
                totalFileSize: 0,*/
            });
            setFormMessages({
              ...formMessages,
              successMessage: true,
              messageSent: true,
              errorMessage: '',
              errorSuglasnostPodaci: '',
              errorSuglasnostPravila: '',
              errorAnswers: ''
            });
            document.querySelector(".modalContainer").classList.add("modaThankYou");
        }

        // make  submit button active
        document.querySelector(".modalSubmit").classList.remove("loading");

    })
    .catch(function(error) {
        // make  submit button active
        document.querySelector(".modalSubmit").classList.remove("loading");
        if (error.response.data.errors) {
            if (error.response.data.errors.ime_i_prezime) {
                setFormMessages({
                    ...formMessages,
                    errorImePrezime: error.response.data.errors.ime_i_prezime
                });
            }
            if (error.response.data.errors.grad_i_postanski_broj) {
              setFormMessages({
                  ...formMessages,
                  errorGradPosta: error.response.data.errors.grad_i_postanski_broj
              });
            }
            
            if (error.response.data.errors.adresa) {
                setFormMessages({
                  ...formMessages,
                    errorAdresa: error.response.data.errors.adresa
                });
            }
            if (error.response.data.errors.telefon) {
                setFormMessages({
                  ...formMessages,
                    errorTelefon: error.response.data.errors.telefon
                });
            }
            if (error.response.data.errors.email) {
                setFormMessages({
                  ...formMessages,
                    errorEmail: error.response.data.errors.email
                });
            }
            if (error.response.data.errors.odgovor) {
              setFormMessages({
                ...formMessages,
                  errorAnswers: error.response.data.errors.odgovor
              });
            }
          
        } else if (error.response.data.message) {
          setFormMessages({
            ...formMessages,
            errorMessage: error.response.data.message
          });
        }

        if(error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404 ||
            error.response.status === 405 ||
            error.response.status === 406 ||
            error.response.status === 407 ||
            error.response.status === 408 ||
            error.response.status === 413 ||
            error.response.status === 500 ||
            error.response.status === 502 ||
            error.response.status === 503 ||
            error.response.status === 504) {
            setFormMessages({
                ...formMessages,
                errorMessage: "Prišlo je do napake. Poskusite ponovno."
            });
        }

    });
  }

  const ShowMessageImePrezime = () => {
    if (formMessages.errorImePrezime !== '') {
        return (
            <div className="formError my-2"><p>{formMessages.errorImePrezime}</p></div>
        );
    }
  }
  const ShowMessageGradPosta = () => {
    if (formMessages.errorGradPosta !== '') {
        return (
            <div className="formError my-2"><p>{formMessages.errorGradPosta}</p></div>
        );
    }
  }
  const ShowMessageAdresa = () => {
    if (formMessages.errorAdresa !== '') {
        return (
            <div className="formError my-2"><p>{formMessages.errorAdresa}</p></div>
        );
    }
  }
  const ShowMessageEmail = () => {
    if (formMessages.errorEmail !== '') {
        return (
            <div className="formError my-2"><p>{formMessages.errorEmail}</p></div>
        );
    }
  }
  const ShowMessageTelefon = () => {
    if (formMessages.errorTelefon !== '') {
        return (
            <div className="formError my-2"><p>{formMessages.errorTelefon}</p></div>
        );
    }
  }
  const ShowMessageSlikaRacun = () => {
    if (formMessages.errorSlikaRacuna !== '') {
        return (
            <div className="formError my-2"><p>{formMessages.errorSlikaRacuna}</p></div>
        );
    }
  }
  const ShowMessageTekst = () => {
    if (formMessages.errorTekst !== '') {
        return (
            <div className="formError my-2"><p>{formMessages.errorTekst}</p></div>
        );
    }
  }
  const ShowMessage = () => {
    if (formMessages.errorMessage !== '') {
        return (
            <div className="formError"><p>{formMessages.errorMessage}</p></div>
        );
    }
  }
  const ShowMessageSuglasnostPodaci = () => {
    if (formMessages.errorSuglasnostPodaci !== '') {
        return (
            <div className="formError"><p>{formMessages.errorSuglasnostPodaci}</p></div>
        );
    }
    return false;
  }
  const ShowMessageSuglasnostPravila = () => {
    if (formMessages.errorSuglasnostPravila !== '') {
      return (
          <div className="formError"><p>{formMessages.errorSuglasnostPravila}</p></div>
      );
    }
    return false
  }
  const ShowMessageAnswers = () => {
    if (formMessages.errorAnswers !== '') {
      return (
          <div className="formError"><p>{formMessages.errorAnswers}</p></div>
      );
    }
    return false
  }
  
  const showInvalidFileTypeMessage = (file) => {
      setFormMessages({
          ...formMessages,
          errorFileType: "Naložite lahko samo fotografijo v formatu pdf, jpg, png."
      })
  }

  const requiredMessage = () => {
    return <div className="formError">Polje je obvezno</div>
  }

  const handleFileSelected = (event, file) => {
    let reader = new FileReader();

    let filesContents = formFields.filesContents;
    filesContents.push(event.target.result);

    setUploadedFiles(file);
    setFormFields({...formFields, filesContents: filesContents });

    reader.onloadend = () => {
        let filesPreview = formFields.filesPreview;
        filesPreview.push(reader.result);
        setFormFields({ ...formFields, filesPreview: filesPreview });
    }
    // clear error message and save sum of file sizes
    setFormFields({
      ...formFields, 
      totalFileSize: formFields.totalFileSize + file.size,
    })
    
    setFormMessages({
      ...formMessages,
      errorFileType: "",
      errorMessage: "",
      errorImage: ""
    });

    reader.readAsDataURL(file)
  }

  return (
    
    <>
      <div className="w-100 modalForm">
        <div className="modalPrizeImage">
          {selectedItem?.prize_image && <img src={`${require(`../assets/img/nagrade/${selectedItem?.prize_image}`)}`} alt="" />}
          <div className="modalPrizeTitles">
            <ol>
            {selectedItem?.prizes.map((item) => <li key={item.title}><a href={item.url} target="_blank" rel="noreferrer" className={item.url === '' ? 'disabled-link' : ''} dangerouslySetInnerHTML={{__html:item.title}}></a></li>)}
            </ol>
          </div>
        </div>
        <div className="star">
          <div className="starTail">
          {selectedItem?.tail_image && <img src={`${require(`../assets/img/repovi/${selectedItem?.tail_image}`)}`} alt="star tail graphic" />}
          </div>
          <div className="starHead">
            <img src={starHead} alt="star tail graphic" />
          </div>
        </div>
        <div className="align-center py-10" style={{display: formMessages.messageSent ? '' :'none'}}>
          <h2 style={{fontSize: "2rem", lineHeight: "1.2"}}>Vaša prijava je uspešno prejeta! Hvala!</h2>
          {newsletter ? <h2>Uspešno ste se prijavili na naše novice.</h2> : ''}
          <h2 style={{fontSize: "2rem"}}>Srečno!</h2>
          <a href="https://oetker-shop.si/" target="_blank" className="basic-button" rel="noreferrer">Obiščite našo spletno trgovino</a>
        </div>
        <form action="." method="POST" onSubmit={handleSubmit} className="mt-4" style={{display: formMessages.messageSent ? 'none' :''}}>
          <div className="mb-3">
            <label className="pb-1 formLabel">Ime in Priimek:</label>
            <input name="ime_i_prezime" onChange={handleChange}/>
            {formMessages.errorImePrezime === '' ? '' : requiredMessage}
            <ShowMessageImePrezime />
          </div>
          <div className="mb-3">
            <label className="pb-1 formLabel">Mesto in poštna številka:</label>
            <input name="grad_i_postanski_broj" onChange={handleChange} />
            {formMessages.errorGradPosta === '' ? '' : requiredMessage}
            <ShowMessageGradPosta />
          </div>
          <div className="mb-3">
            <label className="pb-1 formLabel">Naslov:</label>
            <input name="adresa" onChange={handleChange} />
            {formMessages.errorAdresa === '' ? '' : requiredMessage}
            <ShowMessageAdresa />
          </div>
          <div className="mb-3">
            <label className="pb-1 formLabel">E-naslov:</label>
            <input name="email" onChange={handleChange}/>
            {formMessages.errorEmail === '' ? '' : requiredMessage}
            <ShowMessageEmail />
          </div>
          <div className="mb-3">
            <label className="pb-1 formLabel">Telefonska številka:</label>
            <input name="telefon" onChange={handleChange}/>
            {formMessages.errorTelefon === '' ? '' : requiredMessage}
            <ShowMessageTelefon />
          </div>
          
          {/*<div className="mb-3">
          <label className="pb-1 formLabel">Priložite fotografijo računa z vidnim datumom nakupa (pdf, jpg, png):</label>
          </div>
          <div className="mb-3 formGroupUpload">
              <div className="fileUpload inline-block mb-2">
                  Naloži
                  <FileInput
                    name="slika_racun"
                    readAs='binary'
                    style={ { opacity: '0' } }
                    onLoad={handleFileSelected}
                    cancelIf={fileIsIncorrectFiletype}
                    onCancel={showInvalidFileTypeMessage}
                    />
              </div>
              <div className="uploadedItems">
                {uploadedFiles ? <div className="uploadedItem italic"><p>{uploadedFiles.name}</p></div> : ''}
              </div>
          </div>
          <ShowMessageSlikaRacun />*/}
          {Object.keys(questionsPerDay).map(day => (
            <div key={day}>
              {questionsPerDay[day].map(question => (
                question.order === selectedItem.id ?
                <div key={question.id} className="question-wrapper">
                  <label className="pb-1 formLabel formLabel-special">{question.text}</label>
                  <div className="flex flex-wrap justify-start">
                    {question.options.map(option => (
                      <div key={option} className="mb-1 flex align-left items-center my-3 mr-2 ml-2 answer-item">
                        <input
                          style={{ outline: 'none', width: '1.4rem', minWidth: '1.4rem', height: '1.4rem', minHeight: '1.4rem', marginRight: '.5rem' }}
                          type="radio"
                          name={`${day}_${question.id}`}
                          value={option}
                          onChange={() => handleAnswers(option)}
                        />
                        <span
                          style={{ fontFamily: 'Roboto Condensed', textAlign: 'left', lineHeight: '1' }}
                          className="pb-1"
                        >
                          {option}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                : null
              ))}
            </div>
          ))}
          <div className="mb-1 flex -top-2 relative">{!answersAdded ? <ShowMessageAnswers /> : ''}</div>
          <div className="flex mt-0 my-4"><ShowMessage /></div>
          <div className="mb-1 flex align-left my-2">
            <input style={{ outline: 'none', width: '1.4rem', marginRight: '.5rem' }} type="checkbox" name="suglasnost_podaci" onChange={handleChangeSuglasnostPodaci}/>
            <span style={{ fontFamily: 'Roboto Condensed', textAlign: 'left', lineHeight: '1' }} className="pb-1">Strinjam se z obdelavo <a style={{ textDecoration: 'underline' }} href="https://www.oetker.si/si-si/footer-meta/varstvo-osebnih-podatkov" rel="noreferrer" target="_blank">osebnih podatkov</a></span>
          </div>
          <div className="mb-1 flex -top-2 relative">{!suglasnostPodaci ? <ShowMessageSuglasnostPodaci />: ''}</div> 
          <div className="mb-1 flex align-left my-2">
            <input style={{ outline: 'none', width: '1.8rem', marginRight: '.5rem' }} type="checkbox" name="suglasnost_pravila" onChange={handleChangeSuglasnostPravila}/>
            <span style={{ fontFamily: 'Roboto Condensed', textAlign: 'left', lineHeight: '1' }} className="pb-1 align-left">Prebral/-a sem in strinjam se s <a style={{ textDecoration: 'underline' }} href="/Pravila nagradne igre_Xmas campaign slo.doc" rel="noreferrer" target="_blank"> pravili nagradne igre.</a></span>
          </div>
           <div className="mb-1 flex -top-2 relative">{!suglasnostPravila ?<ShowMessageSuglasnostPravila />: ''}</div>
          <div className="mb-1 flex align-center my-2 text-left">
            <input style={{ outline: 'none', width: '1.4rem', marginRight: '.5rem' }} type="checkbox" name="prijava_newsletter" onChange={handleChangeNewsletter}/>
            <span style={{ fontFamily: 'Roboto Condensed', textAlign: 'left', lineHeight: '1' }} className="pb-1 align-left">Želim se prijaviti pri Dr. Oetker novice</span>
          </div>
          <div>
            <button className="modalSubmit" type="submit">Pošlji</button>
          </div>
        </form>
      </div>
    </>
  )
}

const CalendarItem = (props) => {

  const {
    grid_image,
    setIsModalOpen,
    setSelectedItem,
    isActive
  } = props

  const handleItemClick = (e) => {
    setSelectedItem(advent_days.find(item => item.id === props.id))
    setIsModalOpen(true)
    document.querySelector("body").classList.add("overflow-hidden");
  }

  return <div
    style={{
      backgroundImage: `url(${require(`../assets/img/kucice/${grid_image}`)})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      objectFit: 'cover',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer'
    }}
    className={`relative h-20 xs:h-28 lg:h-32 xl:h-36 transition ease-in-out ${props.isActive ? "lg:hover:-translate-y-2 0 duration-200" : "not-active"}`}
    onClick={isActive ? handleItemClick : undefined}
  ><span className="item-overlay"></span></div>
}

const Calendar_SI = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [isActive, setIsActive] = useState(false)
  const [currentDay, setCurrentDay] = useState(new Date().getDate())

  const handleCloseModal = () => {
    setIsModalOpen(false)
    document.querySelector("body").classList.remove("overflow-hidden");
  }

  return (
    <div className="grid gap-2 grid-cols-4 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 md:gap-4">
      {advent_days.map(day => (
        <CalendarItem
          key={day.id}
          {...day}
          setIsModalOpen={setIsModalOpen}
          setSelectedItem={setSelectedItem}
          isActive={currentDay === day.id ? true : false}
        />
      ))}
      <Modal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
      >
        <CalendarForm
          selectedItem={selectedItem}
        />
      </Modal>
    </div>
  )
}

export default Calendar_SI