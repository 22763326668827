import React from 'react'
import mainContentStar from '../assets/img/grafika/ZVIJEZDA-natpis-HR.png'
import mainContentPrizes from '../assets/img/HR-ZBIRNE-nagrade.png'

const DobitniciContent = () => {

  return (
    <article className="mainContent pb-6">
      <div className="px-2 lg:px-9 mt-10 lg:mt-2">
        <h3 className="font-bold text-xl sm:text-2xl lg:text-3xl max-w-[60ch] mx-auto mb-6 lg:mb-6">Zmagovalci</h3>
        <div className="flex flex-wrap justify-between">
          <div className="flex-1 min-w-full md:min-w-[50%] px-0 md:px-2">
            <p className='font-bold'>01.12.2024.</p>
            <p>Martina Makselj, Idrija</p>
            <br />
            <p className='font-bold'>02.12.2024.</p>
            <p>Sintija Jurič, Loče</p>
            <br />
            <p className='font-bold'>03.12.2024.</p>
            <p>Andrej Ulaga, Preserje</p>
            <br />
            <p className='font-bold'>04.12.2024.</p>
            <p>Jasmina Detela, Podgorje</p>
          </div>
          <div className="flex-1 min-w-full md:min-w-[50%] px-0 md:px-2">
            <p className='font-bold'>05.12.2024.</p>
            <p>Natalija Govedić, Mirna</p>
            <br />
            <p className='font-bold'>06.12.2024.</p>
            <p>Monika Perko Kos, Straža</p>
            <br />
            <p className='font-bold'>07.12.2024.</p>
            <p>Nejc Karlo, Skofljica</p>
            <br />
            <p className='font-bold'>08.12.2024.</p>
            <p>Monika Perko Kos, Straža</p>
          </div>
        </div>
      </div>
    </article>
  )

}

export default DobitniciContent